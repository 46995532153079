import * as React from 'react'
import styled from 'styled-components'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import SpinToWin from 'src/components/SpinToWin'
import SEO from 'src/components/shared/SEO'
import { H1, H6 } from 'src/components/shared/text'
import { media } from 'src/styles/util'
import benefitIcon from 'src/assets/img/spin-to-win/03/icon.svg'

const StyledH1 = styled(H1)`
  white-space: nowrap;

  ${media.mobile`
    font-size: 42px;
    line-height: 1;
  `}
  ${media.tablet`
    font-size: 76px;
  `}
  ${media.desktop`
    font-size: 96px;
    line-height: 100px;
  `}
`

const SubtitleTextBox = styled.div`
  width: 100%;
`

const StyledH6 = styled(H6)`
  ${media.mobile`
    font-size: 24px;
    line-height: 36px;
  `}
  ${media.tablet`
    font-size: 44px;
    line-height: 56px;
    white-space: pre-line;
  `}
`

const PRODUCT_CONFIG = {
  lookupKey: 'wheel-03',
  slug: 'skin-and-coat',
  colors: {
    primary: '#FFCFDB',
    secondary: '#EEB7CC',
    ticker: '#E2A6BF'
  },
  aboveTheFold: {
    wheelBaseSVG: null,
    verticalOrientation: false,
    copyOne: 'It’s Your Lucky Day!',
    copyTwo: 'Spin to Win',
    copyThree: 'Free Products & Prizes',
    prizeUrl: 'starter-bundle-holiday',
    promo: {
      code: null,
      discount: null,
      component: (
        <>
          <StyledH1>Congratulations</StyledH1>
          <SubtitleTextBox>
            <StyledH6>
              {`You’ve just won \n`}
              <b>1 Free Tin of Skin & Coat</b>
              {` \nwith purchase of a Starter Bundle`}
            </StyledH6>
          </SubtitleTextBox>
        </>
      )
    }
  },
  benefits: {
    title: 'For hydrated skin & a strong, healthy coat',
    items: [
      {
        label: 'Wild Alaskan \nSalmon Oil',
        detail: ''
      },
      {
        label: 'Promotes Healthy Skin & Coat ',
        detail: ''
      },
      {
        label: 'Rich in Healthy Omega-3 & DHA',
        detail: ''
      },
      {
        label: 'Helps Alleviate Shedding',
        detail: ''
      }
    ],
    icon: benefitIcon,
    collapseMinHeight: 100
  }
}

const WheelThree: React.FC<PageProps> = ({ location }) => {
  const { copyTwo, copyThree } = PRODUCT_CONFIG.aboveTheFold
  const metaTitle = `${copyTwo} - ${copyThree}`
  return (
    <>
      <SEO title={metaTitle} location={location} />

      <Layout location={location}>
        <SpinToWin details={PRODUCT_CONFIG} />
      </Layout>
    </>
  )
}

export default WheelThree
